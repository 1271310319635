<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>What's Your Volley Age?</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div class="title">We get it... Volleyball can be confusing. Indoor, beach, AAU, p1440, BVNE, AVP, USAV, OMG, AHHGG ;-)</div>
            <div>There are several different age cutoffs for different organizations for different reasons. So instead of explaining it all and you falling asleep, Let's just give you answers. Enter your birthdate below.</div>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-dialog
                    ref="dobPicker"
                    v-model="dobPicker"
                    width="290px"
                    :return-value.sync="dob"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        label="Birthdate"
                        readonly
                        v-model="dobF"
                        clearable
                        persistent-hint
                        :hint="`What is your birthdate?`"
                        color="color3"
                        prepend-icon="fas fa-calendar-alt"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="dobPickerPicker"
                      color="color3"
                      :header-color="darkColor"
                      v-model="dob"
                      @change="$refs.dobPicker.save(dob)"
                    ></v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-dialog
                    ref="asOfPicker"
                    v-model="asOfPicker"
                    width="290px"
                    :return-value.sync="asOf"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        label="As of"
                        readonly
                        v-model="asOfF"
                        clearable
                        persistent-hint
                        :hint="'If you want to know the age at a specific date, enter the date here'"
                        color="color3"
                        prepend-icon="fas fa-calendar-alt"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="color3"
                      :header-color="darkColor"
                      v-model="asOf"
                      @change="$refs.asOfPicker.save(asOf)"
                    ></v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-expand-transition>
            <v-card-text v-if="ageResults" class="text-center">
              <v-alert type="info" :value="diffSeason" text>
                For the {{mySeason}} season:
              </v-alert>
              <template v-if="diffSeason">
              </template>
              <template v-if="ageResults.diff">
                <div class="text-h1">Beach:</div>
                <div class="text-h3">{{ageResults.ad}}</div>
                <div>for AAU, p1440, & AVP</div>
                <v-divider></v-divider>
                <div class="text-h3">{{ageResults.ado}}U</div>
                <div>for BVNE</div>
                <v-divider></v-divider>
                <div class="text-h3">{{ageResults.iad}}</div>
                <div>for USAV</div>
                <v-divider></v-divider>
                <div class="text-h1">Indoor:</div>
                <div class="text-h3">{{ageResults.iad}}</div>
              </template>
              <template v-else class="text-h1">
                <div class="text-h1">You are</div>
                <div class="text-h1">{{ageResults.ad}}</div>
              </template>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { ageDivision, indoorAgeDivision, ageDayOf, seasonFromMoment, season, indoorSeason, indoorSeasonFromMoment } from '@/classes/ProfileHelpers'

export default {
  data () {
    return {
      dobPicker: false,
      dob: null,
      asOfPicker: false,
      asOf: null,
      gradyear: null
    }
  },
  computed: {
    ...mapGetters(['darkColor']),
    ageResults () {
      return this.dob ? {
        ad: ageDivision(this.dob, this.gradyear, this.mySeason),
        iad: indoorAgeDivision(this.dob, this.gradyear, this.myISeason),
        ado: ageDayOf(this.dob, this.asOf),
        get diff () { return !(this.ad === this.iad) },
        season: this.mySeason
      } : false
    },
    dobF: {
      get () {
        return this.dob ? moment(this.dob.replace('Z', '')).format('MM/DD/YYYY') : ''
      },
      set (val) {
        this.dob = val
      }
    },
    asOfF: {
      get () {
        return this.asOf ? moment(this.asOf.replace('Z', '')).format('MM/DD/YYYY') : ''
      },
      set (val) {
        this.asOf = val
      }
    },
    mySeason () {
      return (this.asOf && seasonFromMoment(moment(this.asOf))) || season()
    },
    myISeason () {
      return (this.asOf && indoorSeasonFromMoment(moment(this.asOf))) || indoorSeason()
    },
    diffSeason () {
      return this.mySeason && this.mySeason !== season()
    }
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    }
  },
  watch: {
    dobPicker (val) {
      val && this.$nextTick(() => {
        this.$refs.dobPickerPicker.activePicker = 'YEAR'
      })
    }
  }
}

</script>
